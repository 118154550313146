











import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'

@Component
export default class ButtonFileField extends AbstractField {

  onFileChange(): void {
    console.log(this.inputRef)
  }

  get inputRef(): any {
    return this.$refs.inputRef
  }
}
